const button = document.getElementById('toggle-menu-button');
const header = document.querySelector('.header');

if (button && header) {
    button.addEventListener('click', function(event) {
        event.preventDefault();

        if (this.classList.contains('is-active')) {
            this.classList.remove('is-active');
            header.classList.remove('header_active');
        } else {
            this.classList.add('is-active');
            header.classList.add('header_active');
        }
    });
}

window.addEventListener('scroll', (event) => {
    if (window.pageYOffset >= 85) {
        document.body.classList.add('header_fixed');
    } else {
        document.body.classList.remove('header_fixed');
    }
});
