import './slider';

const orderForm = document.querySelector('.order .form');
const orderSuccess = document.querySelector('.order__success');

if (orderForm && orderSuccess) {
    orderForm.addEventListener('submit', (event) => {
        event.preventDefault();
        orderForm.style.display = 'none';
        orderSuccess.style.display = 'block';
    });

    orderSuccess.addEventListener('click', (event) => {
        event.preventDefault();
        orderForm.reset();
        orderForm.style.display = 'block';
        orderSuccess.style.display = 'none';

        const firstInput = orderForm.querySelector('input');

        if (firstInput) {
            firstInput.focus();
        }
    })
}